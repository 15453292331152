<template>
	<transition name="fade">
		<section v-show="!loading" class="application-detail">
			<DownloadModal v-if="modalActive" :files="files" @close="handleModalClosure" />

			<StsSectionHeader :title="detailSectionTitle" />

			<StsCard style="padding: 0.5rem 0.75rem; margin: 0px 1rem">
				<section class="link-container">
					<div class="title">{{ estimatedLoanTermsDisplay }}<sup v-if="this.superscripts.loanTerms">{{ this.superscripts.loanTerms }}</sup></div>

					<StsButton
						v-if="hasPreQualificationDocument"
						title="VIEW PRE-APPROVAL LETTER"
						type="text"
						buttonType="button"
						alternative
						small
						noIcon
						@click="downloadPreApprovalFiles"
					/>
				</section>

				<section class="columns">
					<StsListDisplay :title="amountTitleDisplay" :description="amountDetailDisplay" isColumn :superscript="this.superscripts.amount"/>
					<StsListDisplay :title="termTitleDisplay" :description="termDetailDisplay" isColumn :superscript="this.superscripts.term"/>
					<StsListDisplay :title="rateTitleDisplay" :description="rateDetailDisplay" isColumn :superscript="this.superscripts.rate"/>
					<StsListDisplay :title="monthlyPaymentTitleDisplay" :description="monthlyPaymentDetailDisplay" isColumn :superscript="this.superscripts.monthlyPayment"/>
				</section>
			</StsCard>

			<section class="application-collateral">
				<StsCard v-if="hasCollateral" style="padding: 0.5rem 0.75rem; margin: 0px 1rem">
					<section>
						<div class="title">YOUR COLLATERAL</div>
					</section>

					<section>{{ collateralDescription }}</section>
				</StsCard>
			</section>
		</section>
	</transition>
</template>

<script>
	import { createToastInterface } from 'vue-toastification';
	import { StsButton, StsCard, StsListDisplay, StsSectionHeader } from '@/components/SwitchThink';
	import { toastConfig } from '@/utils';
    import { DocumentType, LegalTermType, LoanDetailType } from '@/constants';
	import showSpinner from '@/mixins/showSpinner';
	const toast = createToastInterface(toastConfig);

	export default {
		name: 'ApplicationDetail',
		components: {
			StsCard,
			StsListDisplay,
			StsButton,
			StsSectionHeader,
			DownloadModal: () => import('@/views/Partials/DownloadModal')
		},
		mixins: [showSpinner],
		props: {
			moduleName: {
				type: String,
				required: true
			},
			model: {
				type: Object,
				required: true
			},
			reload: {
				type: Boolean,
				required: true
			},
			disclosures: {
				type: Array,
				required: false
			}
		},
		data() {
			return {
				detailSectionTitle: '',
				hasPreQualificationDocument: false,
				hasDocuments: false,
				files: [],
				modalActive: false,
				collateralDescription: '',
				legalTermTitles: {
					loanTerms: "ESTIMATED LOAN TERMS:",
					rate: "Rate:",
					amount: "Amount:",
					term: "Term:",
					monthlyPayment: "Monthly Payment:"
				},
				superscripts: {
					loanTerms: "",
					term: "",
					rate: "",
					amount: "",
					monthlyPayment: ""
				},
				overrides: {
					rateDetail: "",
					termDetail: "",
					monthlyPaymentDetail: "",
					amountDetail: "",
					rateTitle: "",
					termTitle: "",
					monthlyPaymentTitle: "",
					amountTitle: "",
					estimatedLoanTerms: ""
                }
			};
		},
		computed: {
			hasCollateral() {
				return this.collateralDescription && this.collateralDescription.length > 0;
			},
			loanDetail() {
				return {
					loanRate: this.model.loanDetail.loanRate,
                    loanTermInMonths: this.model.loanDetail.loanTermInMonths,
                    monthlyPayment: this.model.loanDetail.monthlyPayment,
					totalLoanAmount: this.model.loanDetail.totalLoanAmount
				};
			},
			amountTitleDisplay() {
				return this.overrides.amountTitle ? this.overrides.amountTitle : this.legalTermTitles.amount;
			},
            rateTitleDisplay() {
                return this.overrides.rateTitle ? this.overrides.rateTitle : this.legalTermTitles.rate;
			},
            termTitleDisplay() {
                return this.overrides.termTitle ? this.overrides.termTitle : this.legalTermTitles.term;
			},
            monthlyPaymentTitleDisplay() {
                return this.overrides.monthlyPaymentTitle ? this.overrides.monthlyPaymentTitle : this.legalTermTitles.monthlyPayment;
			},
            amountDetailDisplay() {
                return this.overrides.amountDetail ? this.overrides.amountDetail : this.loanDetail.totalLoanAmount;
			},
			rateDetailDisplay() {
                return this.overrides.rateDetail ? this.overrides.rateDetail : this.loanDetail.loanRate;
			},
			termDetailDisplay() {
				return this.overrides.termDetail ? this.overrides.termDetail : this.loanDetail.loanTermInMonths;
			},
			monthlyPaymentDetailDisplay() {
				return this.overrides.monthlyPaymentDetail ? this.overrides.monthlyPaymentDetail : this.loanDetail.monthlyPayment;
			},
            estimatedLoanTermsDisplay() {
                return this.overrides.estimatedLoanTerms ? this.overrides.estimatedLoanTerms : this.legalTermTitles.loanTerms;
			}
		},
		watch: {
			async reload(newVal, oldVal) {
				if (newVal !== oldVal && newVal) {
					await this.load();
					return this.$emit('reloaded');
				}
			}
		},
		async created() {

			await this.load();
		},
		methods: {
			async downloadPreApprovalFiles() {
				await this.downloadFiles(DocumentType.PRE_QUALIFICATION_LETTER);
			},
			async downloadFiles(documentType) {
				const documents = await this.$store.dispatch(`${this.moduleName}/getDocumentsByType`, documentType);

				if (documents && documents.length > 0) {
					this.files = documents;

					if (documents.length === 1) {
						const document = documents[0];
						window.open(document.downloadLink, document.fileName);
					} else {
						this.modalActive = true;
					}
				}
			},
			async handleModalClosure() {
				this.modalActive = false;
				await this.$nextTick();

				this.showSpinner({ submitting: false });
			},
			async load() {
				try {
					this.showSpinner({ loading: true });

					const [hasDocuments, detailSectionTitle, collateralDescription, overrides] =
						await Promise.all([
							this.$store.dispatch(`${this.moduleName}/getDocuments`),
							this.$store.dispatch(`${this.moduleName}/getDetailSectionTitle`),
                            this.$store.dispatch(`${this.moduleName}/getCollateralDescription`),
                            this.$store.dispatch(`${this.moduleName}/getLoanDetailOverrides`)
						]);

					const hasPreQualificationDocument = await this.$store.dispatch(`${this.moduleName}/hasPreQualificationDocument`);

					this.detailSectionTitle = detailSectionTitle;
					this.hasDocuments = hasDocuments;
					this.hasPreQualificationDocument = hasPreQualificationDocument;
					this.collateralDescription = collateralDescription;

					this.superscripts.loanTerms = this.buildSuperscripts(LegalTermType.LOANTERMS);
                    this.superscripts.term = this.buildSuperscripts(LegalTermType.TERM);
                    this.superscripts.rate = this.buildSuperscripts(LegalTermType.RATE);
                    this.superscripts.amount = this.buildSuperscripts(LegalTermType.AMOUNT);
					this.superscripts.monthlyPayment = this.buildSuperscripts(LegalTermType.MONTHLYPAYMENT);

					this.buildOverrides(overrides);

					return this.showSpinner({ loading: false });
				} catch (error) {
					this.showSpinner({ submitting: false });
					return toast.error(error);
				}
			},
			async refresh() {
				return this.$emit('submit');
			},
			buildSuperscripts(termType) {
				let parsedSuperscripts = [];
				for (let i = 0; i < this.disclosures.length; i++) {
					if (this.disclosures[i].legalTerm == termType) {
						parsedSuperscripts.push(this.disclosures[i].superscript);
                    }
                }
				return parsedSuperscripts.join(" ");
			},
			buildOverrides(overrides) {
				if (overrides && overrides.length > 0) {
					overrides.forEach(x => this.mapOverrideByType(x));
				}
			},
			mapOverrideByType(override) {
				switch (override.loanDetailType) {
					case (LoanDetailType.AMOUNT_TITLE):
						this.overrides.amountTitle = override.value;
						break;
                    case (LoanDetailType.TERM_TITLE):
                        this.overrides.termTitle = override.value;
						break;
                    case (LoanDetailType.RATE_TITLE):
                        this.overrides.rateTitle = override.value;
						break;
                    case (LoanDetailType.MONTHLY_PAYMENT_TITLE):
                        this.overrides.monthlyPaymentTitle = override.value;
						break;
                    case (LoanDetailType.AMOUNT):
                        this.overrides.amountDetail = override.value;
						break;
                    case (LoanDetailType.TERM):
                        this.overrides.termDetail = override.value;
						break;
                    case (LoanDetailType.RATE):
                        this.overrides.rateDetail = override.value;
						break;
                    case (LoanDetailType.MONTHLY_PAYMENT):
                        this.overrides.monthlyPaymentDetail = override.value;
						break;
                    case (LoanDetailType.ESTIMATED_LOAN_TERMS):
                        this.overrides.estimatedLoanTerms = override.value;
						break;
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	.application-detail {
		.title {
			font-weight: 300;
			font-size: 1.24rem;
			font-style: normal;
			color: #003a79;
		}

		.link-container {
			display: flex;

			.title {
				flex: 1;
			}
		}

		.columns {
			margin-top: 0.5rem;
		}

		@media screen and (min-width: 850px) {
			.columns {
				display: grid;
				grid-template-columns: repeat(4, auto);
				justify-items: center;
				align-items: center;
			}
		}
	}

	.application-collateral {
		margin-top: 1rem;
	}
</style>
